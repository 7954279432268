





<template>
  <div>



<v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      > 
        <base-material-card icon="mdi-account-outline">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              Edit Profile
              <span class="body-1">— Complete your profile</span>
            </div>
          </template>

          <v-form  @submit.stop.prevent="onSubmit">
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    label="Company ID "
                    disabled
                    :value="currentUser.company_relation"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    class="purple-input"
                    label="User Name"
                    :value="currentUser.username"
                  />
                </v-col>

           

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="First Name"
                    class="purple-input"
                    :value="currentUser.FirstName"

                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Last Name"
                    class="purple-input"
                    :value="currentUser.LastName"
                  />
                </v-col>

           <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    label="Business Title"
                    class="purple-input"
                     v-model="form.businesstitle"
                  />

                    <v-checkbox
              label="Show in Contacts"
              class="mt-0"
               v-model="form.show_businesstitle"
            />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    label="Phone #"
                    class="purple-input"
                      name="contact_phone"
                      v-model="form.contactphone"
                  /> 

                  <v-checkbox
              label="Show in Contacts"
              class="mt-0"
               v-model="form.show_contactphone"
            />
                </v-col>

                     <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                  disabled
                    label="Email Address (can not change)"
                    class="purple-input"
                     v-model="$v.form.email.$model"
                      :state="validateState('email')"
                  />

  <v-checkbox
              label="Show in Contacts"
              class="mt-0"
               v-model="form.show_contactemail"
            />

                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-checkbox
                    label="Receive email notices of unread company messages"
                    class="mt-0"
                    v-model="form.get_notice"
                  />
                </v-col>

                    <v-col
                  cols="12"
                  md="6"
                > 
                    <v-switch
      v-model="form.expert"
      inset
      label="Enable Expert Mode"
    ></v-switch>


                </v-col>


                 <v-divider class="mx-4"></v-divider>

        <v-col
                  cols="12"
                  md="12"
                >

  <v-text-field
        ref="password"
        type="password"
       v-model="$v.form.password.$model"
                      :state="validateState('password')"
        label="Change Password"
       
      ></v-text-field>





        </v-col>
    <v-divider class="mx-4"></v-divider>


              
                <v-col
                  cols="12"
                  class="text-right"
                >
        

                  <v-btn
                  type="submit"
                   ref="kt_login_signup_submit"
                    color="success"
                    class="mr-0"
                   
                  >
                    Update Profile
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <base-material-card
          class="v-card-profile"
          avatar="https://demos.creative-tim.com/vue-material-dashboard/img/marc.aba54d65.jpg"
        >

<div class="text-center">
<i aria-hidden="true" class="v-icon notranslate mdi mdi-format-quote-close theme--light black--text test-center" style="font-size: 40px;"></i>
      
</div>


          <v-card-text class="text-center">
          

            <h4 class="display-2 font-weight-light mb-3 black--text">
A  wise man once said:              </h4>

            <p class="font-weight-light grey--text">
             If a tree falls in the woods and you did not have a valid contact info, did it make a sound...
            </p>

      
          </v-card-text>
        </base-material-card>
      
      </v-col>
    </v-row>
  </v-container>



  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import { email, required, minLength } from "vuelidate/lib/validators";
import { UPDATE_USER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
export default {
  mixins: [validationMixin],
  name: "profile",
  components: {},
  data() {
    return {
      tabIndex: 0,
      form: {
        email: "",
        password: "",
        contactemail: "",
        contactphone: "",
        businesstitle: "",
        show_contactemail: true,
        show_contactphone: true,
        show_businesstitle: true,
        get_notice: true,
         expert: false
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      },
    }
  },
  computed: {
    ...mapGetters([
      "layoutConfig",
      "currentUser"
    ]),

    config() {
      return this.layoutConfig();
    }
  },
  mounted() {
    setTimeout(() => {
      this.form.email = this.currentUser.email;
      if(this.currentUser.contact_email != null)
        this.form.contactemail = this.currentUser.contact_email;
      else
        this.form.contactemail = this.currentUser.email;
      this.form.contactphone = this.currentUser.contact_phone;
      this.form.businesstitle = this.currentUser.business_title;
      if(this.currentUser.show_contact_email != null)
        this.form.show_contactemail = this.currentUser.show_contact_email;
      if(this.currentUser.show_contact_phone != null)
        this.form.show_contactphone = this.currentUser.show_contact_phone;
      if(this.currentUser.show_business_title != null)
        this.form.show_businesstitle = this.currentUser.show_business_title;
      if(this.currentUser.get_notice != null)
        this.form.get_notice = this.currentUser.get_notice;
        if(this.currentUser.expert != null)
        this.form.expert = this.currentUser.expert;
    }, 1000);
    // set the tab from previous


   
  
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    /**
     * Reset config
     */
    reset(event) {
      event.preventDefault();
      // remove existing saved config
      localStorage.removeItem("config");
      window.location.reload();
    },



    /**
     * Submit form
     * @param event
     */
    onSubmit() {
      
      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];

      // dummy delay
      setTimeout(() => {
        this.$store
          .dispatch(UPDATE_USER, {
            email: email,
            password: password,
            contact_email: this.form.contactemail,
            contact_phone: this.form.contactphone,
            business_title: this.form.businesstitle,
            show_contact_email: this.form.show_contactemail,
            show_contact_phone: this.form.show_contactphone,
            show_business_title: this.form.show_businesstitle,
            get_notice: this.form.get_notice,
            expert: this.form.expert
          })
          .then(() => {
            window.location.reload();
               Swal.fire({
            title: "",
            text: "Saved successfully.",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
            
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    }
  }
};
</script>
